<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div
      class="has-text-centered has-background-primary"
      style="margin-bottom: 20px"
    >
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading }}
      </h1>
    </div>
    <div style="max-width: 95%; margin: auto">
      <div id="body-content-area" style="overflow: hidden" :submit="validateAndSubmit">
        <div v-if="isLoading">
          <Loading />
        </div>
        <div v-else>
            <div id="buttongroup" style="padding-top: 20px;">
                <div class="field is-grouped" style="display: flex; justify-content: flex-end!important; gap: 1rem;">
                    <button :disabled="!$hasPermissions(clientSession, ['ANNUAL_QUESTIONNAIRE'], 2)" type="submit" class="button is-accent has-text-white" @click="validateAndSubmit">Save</button>
                    <a class="button is-light" v-on:click="resetForm();$router.go(-1)">Cancel</a>
                </div>
            </div>
          <div id="pagelayout">

            <div class="columns" style="flex-wrap: wrap;">
                <div class="column" style="min-width: 100%;">
                    <div v-if="errors && errors.length">
                        <div v-for="error in errors" :key="error" style="color: red;">{{ error }}</div>
                    </div>
                </div>
                <div class="column" style="max-width: 50%">
                  <div
                    class="block"
                    style="
                      padding: 0px 0px 7px 0px;
                      border-bottom: solid 1px lightgray;
                    "
                  >
                    <span class="has-text-weight-bold"
                      >Default Properties</span
                    >
                  </div>
                  <div class="block">
                    <div class="flex-container" style="margin-bottom: 1rem">
                      <div class="flex-field">
                        <label for="" class="label">
                          Default Message
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>

                        <div class="field">
                          <textarea
                            v-model="input.defaultMessage"
                            rows="5"
                            cols="50"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="flex-container">
                      <div class="flex-field">
                        <label class="label is-size-7">
                          Default Due Date
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <kendo-datepicker
                          id="dueDate"
                          name="due date"
                          v-validate="'required'"
                          v-model="input.defaultDueDate"
                          :format="'yyyy-MM-dd'"
                        >
                        </kendo-datepicker>
                      </div>
                      <div class="flex-field">
                        <label class="label is-size-7">
                          Default Info 'As Of' Date
                          <span
                            class="has-text-warning"
                            style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <kendo-datepicker
                          id="asOfDate"
                          name="info date"
                          v-validate="'required'"
                          v-model="input.defaultAsOfDate"
                          :format="'yyyy-MM-dd'"
                        >
                        </kendo-datepicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column" style="max-width: 50%">
                  <div
                    class="block"
                    style="
                      padding: 0px 0px 7px 0px;
                      border-bottom: solid 1px lightgray;
                    "
                  >
                    <span class="has-text-weight-bold"
                      >Default Reminder Schedule</span
                    >
                    </div>
                    <div
                    v-for="(reminder, i) in notificationsTable"
                    :key="i"
                    class="flex-container flex-left"
                    style="margin-top: 1rem; width: 100%; gap: 0.5rem !important"
                  >
                    <div class="flex-field" style="max-width: 150px">
                      <label class="label is-size-7">
                        Reminder Date/Time
                        <span class="has-text-warning" style="padding-left: 3px"
                          >*</span
                        >
                      </label>
                      <kendo-datepicker
                        id="reminderDate"
                        style="width: 100%"
                        v-model="reminder.remindAt"
                        :format="'yyyy-MM-dd'"
                      />
                      <input
                        v-model="reminder.remindAt"
                        v-show="false"
                        :name="`reminder date ${i}`"
                        v-validate="'required'"
                      />
                    </div>
                    <div
                      class="flex-field flex-left flex-container"
                      style="
                        width: calc(100% - 0.5rem - 200px) !important;
                        position: relative;
                      "
                    >
                      <div class="flex-field" style="width: 100%">
                        <label class="label is-size-7">
                          Email Template
                          <span class="has-text-warning" style="padding-left: 3px"
                            >*</span
                          >
                        </label>
                        <div class="select" style="width: 100%">
                          <select
                            v-model="reminder.emailTemplateId"
                            :name="`email template ${i}`"
                            v-validate="'required'"
                            style="width: 100%"
                          >
                            <option
                              v-for="opt in emailTemplates.filter(x => QuestionnaireType.id === x.emailTemplateTypeCategoryId)"
                              :key="opt.id"
                              :value="opt.id"
                              :selected="
                                parseInt(opt.id) ===
                                parseInt(reminder.emailTemplateId)
                              "
                            >
                              {{ opt.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="flex-field" style="padding: 0; padding-top: .5rem;">
                      <label  class="label is-size-7">
                        &nbsp;
                      </label>
                      <a
                        class="button is-light"
                        v-on:click="removeReminder(reminder)"
                        >-</a
                      >
                    </div>
                  </div>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: flex-end;
                    "
                  >
                    <a
                      class="button is-light"
                      style="margin-left: auto; margin-top: 0.5rem"
                      v-on:click="addReminder"
                      >Add reminder</a
                    >
                  </div>
                  <div
                    class="block"
                    style="
                      padding: 0px 0px 7px 0px;
                      border-bottom: solid 1px lightgray;
                    "
                  >
                    <span class="has-text-weight-bold"
                      >Default On "Send" Notification</span
                    >
                  </div>
                  <div class="select" style="width: 100%;">
                    <select v-model="input.defaultSentEmailTemplate" style="width: 100%;">
                      <option
                        v-for="opt in emailTemplates.filter(x => QuestionnaireType.id === x.emailTemplateTypeCategoryId)"
                        :key="opt.id"
                        :value="opt.id"
                        :selected="
                          parseInt(opt.id) ===
                          parseInt(input.defaultSentEmailTemplate)
                        "
                      >
                        {{ opt.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './../Loading'
import { mapState } from 'vuex'
import { activeSite } from '../../vuex-actions'
import { DatePicker } from '@progress/kendo-dateinputs-vue-wrapper'
import moment from 'moment-timezone'

let pageName = 'Annual Questionnaire - Configuration'

export default {
  components: {
    Loading,
    'kendo-datepicker': DatePicker
  },
  data () {
    return {
      errors: [],
      name: '',
      templateTypeCategories: [],
      input: {
        dueDate: '',
        asOfDate: '',
        portalMessage: ''
      },
      notificationsTable: [],
      isLoading: true,
      selectedField: 'selected',
      emailTemplates: []
    }
  },
  watch: {},
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName.toUpperCase()
    },
    pageheading () {
      return pageName
    },
    QuestionnaireType () {
      if (this.templateTypeCategories && this.templateTypeCategories.length > 0) {
        return this.templateTypeCategories.find(x => x.description === 'Reporting Questionnaire')
      } else {
        return null
      }
    }
  },
  async mounted () {
    await this.getEmailTemplates()
    await this.getEmailTemplateCategories()
    await this.getDefaults()
  },
  methods: {
    async getEmailTemplateCategories () {
      try {
        const route = `/EmailTemplates/TemplateTypeCategories`

        let response = await this.axios.get(route)
        this.templateTypeCategories = response.data
      } catch (error) {
        this.handleApiErr(error)
      }
    },
    addReminder () {
      this.notificationsTable.push({
        emailTemplateId: 0,
        remindAt: moment().format('YYYY-MM-DD')
      })
    },
    async getDefaults () {
      this.isLoading = true
      this.api().annualQuestionnaire.getDefaults((_error, _response) => {
        if (_error) {
        }

        this.input = _response.data.questionnaireDefault
        this.notificationsTable = _response.data.questionnaireDefaultNotifications

        this.isLoading = false
      })
    },
    async getEmailTemplates () {
      try {
        let route = '/email-templates/'
        let response = await this.axios.get(route)
        this.emailTemplates = response.data.records.filter(r => r.emailTemplateTypeId === 2)
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    async validateAndSubmit (e) {
      // TODO: Figure out validation
      if (this.errors.length) {
        this.errors = []
      }

      if (this.input.defaultMessage === '') {
        this.errors.push('Default Message is required')
      }

      if (this.input.defaultDueDate === '') {
        this.errors.push('Default Due Date is required')
      }

      if (this.input.defaultAsOfDate === '') {
        this.errors.push('Default Info As Of Date is required')
      }

      if (this.notificationsTable.length === 0) {
        this.errors.push('At least one notification is required')
      }

      if (moment(this.input.defaultAsOfDate).isAfter(moment())) {
        this.errors.push('Default Info As Of Date must be in the past')
      }

      if (moment(this.input.defaultDueDate).isBefore(moment())) {
        this.errors.push('Default Due Date must be in the future')
      }

      if (this.notificationsTable.length > 0) {
        this.notificationsTable.forEach(x => {
          if (moment(x.remindAt).isBefore(moment())) {
            this.errors.push('Notification dates must be in the future')
          }
        })
      }

      if (this.errors.length > 0) {
        return
      }
      e.stopPropagation()
      e.preventDefault()

      this.isLoading = true

      this.api().annualQuestionnaire.updateDefaults({
        questionnaireDefault: this.input,
        questionnaireDefaultNotifications: this.notificationsTable.map(x => {
          return {
            defaultId: this.input.defaultKey,
            emailTemplateId: x.emailTemplateId,
            remindAt: moment(x.remindAt).format('YYYY-MM-DD')
          }
        })
      }, (_error, _response) => {
        if (_response) {
          try {
            let resp = JSON.parse(_response)
            let status = parseInt(resp.status)
            this.isLoading = false
            if (status >= 200 && resp.status < 300) {
              this.success('Successfully updated defaults')
            } else {
              this.error(resp.response.data)
            }
          } catch (e) {
            // Do nothing
            this.isLoading = false
          }
        }
        this.isLoading = false
      })
    }
  }
}
</script>

<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.grid-button {
  margin-bottom: 5px;
}
.grid-header-controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.k-grid td:first-child {
  color: transparent !important;
}

.k-grid th:first-child {
  color: transparent !important;
}

.grid-container {
  margin-bottom: 1rem;
}

.visible {
  visibility: hidden;
}

.bottom-bar {
  margin-top: 0rem;
  margin-bottom: 1rem;

  display: flex;
  justify-content: flex-end;
}
.k-checkbox:checked {
  background-color: #7fb942;
  color: white;
  border-color: #7fb942;
}
.k-master-row > td:first-child {
  color: transparent !important;
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  vertical-align: center !important;
}
</style>
<style>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}

.subheading {
  font-size: 1em;
  padding: 30px 0 10px 0;
}

div.flex-field {
  display: inline-block;
}

div.flex-field:not(:first-child) {
  padding-left: 20px;
}

.field {
  color: #4a4a4a;
}

.flex-column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.flex-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap !important;
  gap: 0.5rem;
}

.text-left {
  text-align: left !important;
}

.flex-container .flex-field {
  padding: 0px !important;
  margin: 0px !important;
}

.flex-center {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-left {
  justify-content: flex-start !important;
}

.flex-full {
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-full div {
  width: 100%;
}

.flex-full div div {
  width: 100%;
}

.flex-full div div input {
  width: 100%;
}

.field input,
.field textarea,
.flex-field input,
.column input,
.column textarea {
  font-size: 1em;
  background-color: white;
  border-color: #dbdbdb;
  border-radius: 4px;
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
  min-height: 36px;
  color: #363636;
  padding-bottom: calc(0.375em - 1px);
  padding-left: calc(0.625em - 1px);
  padding-right: calc(0.625em - 1px);
  padding-top: calc(0.375em - 1px);
}

/* Datatable CSS */
.v-datatable-light {
  display: inline-block;
  margin: 10px 0;
}
/* End Datatable CSS */

/* Invite Client CSS*/
.invite-client-wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;
  /* padding-right: 2.8rem; */
}

.invite-client-wrapper .control > .select {
  display: flex;
  height: 16rem;
}
/* End Invite Client CSS */
</style>

<style scoped>
.column {
  padding: 0.5rem !important;
  margin: 0rem !important;
}

.show-deleted {
  position: absolute;
  right: 0;
  top: calc(50% - 1rem);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.show-deleted button:not(.button),
.show-deleted a:not(.button) {
  background: transparent;
  border: 0px;
  cursor: pointer;
}

.show-deleted button:not(.button):hover,
.show-deleted a:not(.button):hover {
  text-decoration: underline;
}
</style>
